import { gsap } from "gsap";
import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import { Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import resim1 from "../assets/images/hero-section/1.jpg";
import resim2 from "../assets/images/hero-section/2.jpg";
import resim3 from "../assets/images/hero-section/3.jpg";
import resim4 from "../assets/images/hero-section/4.jpg";
import resim5 from "../assets/images/hero-section/5.jpg";
import resim6 from "../assets/images/hero-section/6.jpg";
import resim7 from "../assets/images/hero-section/7.jpg";
import resim8 from "../assets/images/hero-section/8.jpg";
import resim9 from "../assets/images/hero-section/9.jpg";
import resim10 from "../assets/images/hero-section/10.jpg";

import "./HeroSection.css";

const images = [
  resim2,
  resim3,
  resim4,
  resim5,
  resim6,
  resim7,
  resim8,
  resim9,
  resim10,
];

const HeroSection = ({ isMenuOpen }) => {
  const arrowRef = useRef(null);
  const [showScrollArrow, setShowScrollArrow] = useState(true);
  // const [animationKey, setAnimationKey] = useState(0);

  const animateArrow = () => {
    gsap.to(arrowRef.current, {
      y: "20px",
      duration: 0.5,
      repeat: -1,
      yoyo: true,
    });
  };

  const onScroll = () => {
    if (window.scrollY < 50) {
      setShowScrollArrow(true);
    } else {
      setShowScrollArrow(false);
    }
  };

  useEffect(() => {
    if (showScrollArrow && !isMenuOpen) animateArrow();
  }, [showScrollArrow, isMenuOpen]);

  useEffect(() => {
    animateArrow();

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <section id="home" className="hero-section">
      <div className="hero-slider no-select">
        <Swiper
          modules={[Autoplay, EffectFade]}
          slidesPerView={1}
          allowTouchMove={false}
          loop={true}
          autoplay={{
            delay: 10000,
          }}
          speed={2000}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          // onSlideChange={() => {
          //   console.log("slide changed");
          //   setAnimationKey((prevKey) => prevKey + 1);
          // }}
        >
          {images.map((img, index) => (
            <SwiperSlide key={index}>
              <img src={img} alt={`Slide ${index}`} className={`zoom-image`} />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <div className="warning">
          <div>Ekim ayında hizmetinizdeyiz.</div>
          <div>Sayfa tasarım aşamasındadır. Resimler temsilidir.</div>
        </div> */}
      </div>
      {showScrollArrow && !isMenuOpen && (
        <a href="/#bungalovlar">
          <i
            ref={arrowRef}
            className="fa-solid fa-angles-down scroll-arrow"
          ></i>
        </a>
      )}
    </section>
  );
};

export default HeroSection;
