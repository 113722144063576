import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { ReactComponent as Logo } from "../assets/logo_noktasiz.svg"; // Import SVG as a React component

const Header = ({ isMenuOpen, setIsMenuOpen }) => {
  const [activeMenu, setActiveMenu] = useState("home"); // Add state for active menu item
  const [lastMenuClick, setLastMenuClick] = useState(0);
  const lastMenuClickRef = useRef(lastMenuClick);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    // Update the ref to the latest state value
    lastMenuClickRef.current = lastMenuClick;
  }, [lastMenuClick]);

  const handleMenuClick = (menu) => {
    setLastMenuClick(new Date().getTime());
    setActiveMenu(menu); // Set the active menu item
    setIsMenuOpen(false); // Close the menu on selection
  };

  const handleScroll = () => {
    if (new Date().getTime() - lastMenuClickRef.current < 1000) return;
    const sections = document.querySelectorAll("section, footer");
    const scrollPosition = window.scrollY + 100; // Adjust based on header height
    // Update header background based on scroll position

    sections.forEach((section, index) => {
      const sectionId = section.getAttribute("id");
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (
        scrollPosition >= sectionTop &&
        scrollPosition < sectionTop + sectionHeight
      ) {
        setActiveMenu(sectionId);
      }
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="header">
      <div className="header-blur"></div>
      <div className="header-container">
        <div className="logo">
          <a href="/#home">
            <Logo className="logo-svg" alt="MAYA Sapanca Bungalov Logo" />
          </a>
        </div>
        {/* <div style={{ color: "red", fontWeight: "bold" }}>
          TASARIM AŞAMASINDA, YAKINDA HİZMETİNİZDEYİZ
        </div> */}
        <nav className={`nav ${isMenuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a
                href="#bungalovlar"
                className={activeMenu === "bungalovlar" ? "selected" : ""}
                onClick={() => handleMenuClick("bungalovlar")}
              >
                Bungalovlar
              </a>
            </li>
            <li>
              <a
                href="#galeri"
                className={activeMenu === "galeri" ? "selected" : ""}
                onClick={() => handleMenuClick("galeri")}
              >
                Galeri
              </a>
            </li>
            <li>
              <a
                href="#hizmetler"
                className={activeMenu === "hizmetler" ? "selected" : ""}
                onClick={() => handleMenuClick("hizmetler")}
              >
                Hizmetler
              </a>
            </li>
            <li>
              <a
                href="#kampanyalar"
                className={activeMenu === "kampanyalar" ? "selected" : ""}
                onClick={() => handleMenuClick("kampanyalar")}
              >
                Kampanyalar
              </a>
            </li>
            <li>
              <a
                href="#iletisim"
                className={activeMenu === "iletisim" ? "selected" : ""}
                onClick={() => handleMenuClick("iletisim")}
              >
                İletişim
              </a>
            </li>
          </ul>
          <a
            href="https://rezervasyon.mayabungalov.com"
            className="reservation-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-regular fa-calendar-check"></i> Rezervasyon
          </a>
          <div className="social-icons-container">
            <div className="social-icon">
              <a
                href="https://instagram.com/mayasapanca"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            <div className="social-icon">
              <a
                href="https://www.youtube.com/@MayaSapanca"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </div>
            {/* <div className="social-icon">
              <a
                href="https://www.facebook.com/MayaSapancaBungalov"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
            </div>
            <div className="social-icon">
              <a
                href="https://www.x.com/MayaSapancaBungalov"
                rel="noreferrer"
                target="_blank"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </div> */}
          </div>
        </nav>
        <div className="hamburger" onClick={toggleMenu}>
          <i className={`fas ${isMenuOpen ? "fa-times" : "fa-bars"}`}></i>
        </div>
      </div>
      <div className="social-icons-container">
        <div className="social-icon">
          <a
            href="https://instagram.com/mayasapanca"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
        <div className="social-icon">
          <a
            href="https://www.youtube.com/@MayaSapanca"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-youtube"></i>
          </a>
        </div>
        {/* <div className="social-icon">
          <a
            href="https://www.facebook.com/MayaSapancaBungalov"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
        </div>
        <div className="social-icon">
          <a
            href="https://www.x.com/MayaSapancaBungalov"
            rel="noreferrer"
            target="_blank"
          >
            <i className="fab fa-twitter"></i>
          </a>
        </div> */}
      </div>
    </header>
  );
};

export default Header;
