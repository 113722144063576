import React, { useState, useEffect } from "react";
import "./Footer.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Footer = () => {
  const [activePopup, setActivePopup] = useState(null);

  const openPopup = (agreement) => {
    setActivePopup(agreement);
  };

  const closePopup = () => {
    setActivePopup(null);
  };
  useEffect(() => {
    if (activePopup != null) {
      document.body.style.overflow = "hidden"; // Disable background scroll
    } else {
      document.body.style.overflow = ""; // Re-enable background scroll
    }

    return () => {
      document.body.style.overflow = ""; // Cleanup on unmount
    };
  }, [activePopup]);
  return (
    <footer id="iletisim" className="footer">
      <div className="container">
        <div className="contact-info">
          <h3 className="footer-header">İletişim</h3>
          <div className="contact-item">
            <i className="fas fa-map-marker-alt"></i>
            <p>
              Kurtköy Yavuzselim mah., İstasyon Cad. Souğuksu sok. No:12, 54600
              Sapanca/Sakarya
            </p>
          </div>
          <div className="contact-item">
            <i className="fas fa-envelope"></i>
            <p>
              <a href="mailto:info@mayabungalov.com">info@mayabungalov.com</a>
            </p>
          </div>
          <div className="contact-item">
            <i className="fas fa-phone-alt"></i>
            <p>
              <a href="tel:+905051606292">+90 505 160 6292 (MAYA)</a>
            </p>
          </div>
          {/* <div className="contact-item">
            <i className="fab fa-whatsapp"></i>
            <p>
              <a
                href="https://wa.me/905051606292"
                className="whatsapp-btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Whatsapp'tan yazın
              </a>
            </p>
          </div> */}
        </div>

        <div className="contact-info">
          <h3 className="footer-header">Sözleşmeler</h3>
          <ul className="agreements-list">
            <li>
              <button onClick={() => openPopup("kvkk")}>
                KVKK Aydınlatma Metni
              </button>
            </li>
            <li>
              <button onClick={() => openPopup("gizlilik")}>
                Gizlilik Sözleşmesi
              </button>
            </li>
            <li>
              <button onClick={() => openPopup("mesafelisatis")}>
                Mesafeli Satış Sözleşmesi
              </button>
            </li>
            <li>
              <button onClick={() => openPopup("iade")}>
                İptal ve İade Şartları
              </button>
            </li>
          </ul>
        </div>

        <div className="map">
          <h3 className="footer-header">Konumumuz</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.858910526122!2d30.19073247680645!3d40.69910487139574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cca95ce1fd5ed5%3A0xa5af781f3809bc35!2sMAYA%20SAPANCA%20BUNGALOV!5e0!3m2!1sen!2str!4v1723323498303!5m2!1sen!2str"
            width="100%"
            height="250"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Facility Location"
          ></iframe>
        </div>
      </div>
      <h6 className="licence-text">
        © MAYA Sapanca Bungalov, 2024. Tüm hakları saklıdır.
      </h6>
      {/* Popup Modals */}
      {activePopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-header">
              <button className="close-popup" onClick={closePopup}>
                &times;
              </button>
            </div>
            <div className="popup-text">
              {activePopup === "kvkk" && (
                <div className="popup-container">
                  <h4>
                    Kişisel Verilerin Korunması Kanunu (KVKK) Aydınlatma Metni
                  </h4>
                  <p>
                    MAYA Sapanca Bungalov olarak özel hayatın gizliliğine saygı
                    duyuyor ve önem veriyoruz. Bu nedenle, sizleri 6698 sayılı
                    Kişisel Verilerin Korunması Kanunu (KVKK) kapsamında kişisel
                    verilerinizin kullanımı ve korunması ile ilgili haklarınız
                    konusunda bilgilendirmek istiyoruz.
                  </p>

                  <h5>a) Veri Sorumlusu</h5>
                  <p>
                    MAYA Sapanca Bungalov olarak kişisel verilerinizi, 6698
                    sayılı Kişisel Verilerin Korunması Kanunu ve ilgili sair
                    mevzuat kapsamında, veri sorumlusu sıfatı ile işlediğimizi
                    tarafınıza bildiriyoruz.
                  </p>

                  <h5>b) İşlenen Kişisel Veriler</h5>
                  <p>
                    KVKK ve ilgili sair mevzuata uygun olarak işbu aydınlatma
                    metninde belirtilen amaçlar ve hukuki sebepler kapsamında
                    işlenecek kişisel veri kategori ve açıklamaları aşağıdaki
                    gibidir:
                  </p>
                  <ul>
                    <li>
                      <strong>Kimlik Bilgileri:</strong> Adı-soyadı, doğum yeri
                      ve tarihi, uyruk, T.C Kimlik numarası, Beraberindeki
                      misafir adı-soyadı, doğum yeri ve tarihi
                    </li>
                    <li>
                      <strong>İletişim Bilgileri:</strong> Adres, Telefon
                      numarası, e-posta adresi
                    </li>
                    <li>
                      <strong>Finansal Bilgiler:</strong> Banka hesap bilgileri
                    </li>
                    <li>
                      <strong>Özel Nitelikli Kişisel Veriler:</strong> Fotoğraf,
                      Sağlık durumu ve ilaç kullanım bilgisi
                    </li>
                    <li>
                      <strong>Diğer:</strong> Oda numarası, Araç plaka bilgisi,
                      Sigara kullanım bilgisi, Alerjen Durumu Bilgisi
                    </li>
                  </ul>

                  <h5>c) Kişisel Verilerin Hangi Amaçla İşleneceği</h5>
                  <p>Kişisel verileriniz:</p>
                  <ul>
                    <li>Konaklama hizmetine ilişkin süreçlerin yürütülmesi,</li>
                    <li>
                      MAYA Sapanca Bungalov'un satış ve pazarlama
                      politikalarının yürütülebilmesi için çevrimiçi yazılım
                      sistemlerine kaydedilmesi,
                    </li>
                    <li>
                      MAYA Sapanca Bungalov tarafından sunulan ürün ve
                      hizmetlerden sizleri faydalandırmak için gerekli
                      çalışmaların yapılması,
                    </li>
                    <li>
                      Müşteri memnuniyetinin ölçülmesi ve artırılmasının
                      sağlanması,
                    </li>
                    <li>
                      MAYA Sapanca Bungalov ürün ve hizmetlerine ilişkin şikâyet
                      ve önerilerin toplanması ve değerlendirilmesi,
                    </li>
                    <li>
                      Ürün ve hizmetlerin sizlere önerilmesi, satış ve
                      pazarlaması için pazar araştırması faaliyetlerinin
                      planlanması ve/veya icrası,
                    </li>
                    <li>
                      İş ilişkisi içerisinde olan ilgili kişilerin hukuki,
                      teknik ve ticari iş güvenliğinin temin edilmesi,
                    </li>
                    <li>Hukuk işlerinin takibi ve yürütülmesi,</li>
                    <li>Finans ve/veya muhasebe işlerinin yürütülmesi,</li>
                  </ul>
                  <p>
                    amaçlarıyla KVKK’nın 5 (2) maddesinde belirtilen hukuki
                    sebepler dahilinde işlenecektir. Ayrıca, kişisel
                    verileriniz, MAYA Sapanca Bungalov'un hizmetlerine yönelik
                    kampanya ve yenilikler hakkında sizi bilgilendirmek amacıyla
                    KVKK ve Elektronik Ticaret Kanunu’nda belirtilen hukuki
                    sebepler uyarınca açık rızanız alınarak işlenecektir.
                  </p>

                  <h5>
                    d) İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla
                    Aktarılabileceği
                  </h5>
                  <p>
                    Kişisel verileriniz, aşağıda belirtilen amaçlar ile KVKK’nın
                    8 (2) (a) maddeleri uyarınca ilgili kişinin açık rızası
                    aranmaksızın aktarılacaktır:
                  </p>
                  <ul>
                    <li>
                      İlgili mevzuattan kaynaklanan yasal yükümlülüklerimizin
                      yerine getirilebilmesi amacıyla Emniyet Genel Müdürlüğü
                      ile paylaşılacaktır.
                    </li>
                    <li>
                      Ürün ve hizmetlere dair ücretlerin tahsilatı kapsamında
                      finans ve/veya muhasebe işlerinin yürütülmesi amacıyla,
                      gerekmesi halinde noter, icra daireleri ve/veya mahkemeler
                      ile paylaşılabilecektir.
                    </li>
                    <li>
                      İlgili mevzuat uyarınca yasal yükümlülüklerin yerine
                      getirebilmesi amacıyla, gerekmesi veya talep gelmesi
                      halinde, ilgili kamu kurum ve kuruluşları ile
                      paylaşılabilecektir.
                    </li>
                    <li>
                      Otelde bırakılan ve unutulan eşyaların teslim edilmesi
                      amacıyla anlaşmalı kargo şirketi ile paylaşılacaktır.
                    </li>
                    <li>
                      Hukuki süreçlerin takip edilebilmesi amacıyla
                      avukatlarımız ile sır saklama yükümlülüğü çerçevesinde
                      gerektiği kadar paylaşılabilecektir.
                    </li>
                  </ul>

                  <h5>e) Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</h5>
                  <p>
                    Kişisel verileriniz, bu metnin (c) fıkrasında belirtilen
                    amaçların yerine getirilebilmesi için KVKK’nın 5 (2) ve 6
                    (2) maddelerinde belirtilen hukuki sebeplere dayanarak,
                    otomatik veya otomatik olmayan yöntemlerle sözlü, yazılı
                    veya elektronik ortamda elde edilmektedir:
                  </p>
                  <ul>
                    <li>İlgili kişi tarafından form doldurulması,</li>
                    <li>E-posta iletisi gönderilmesi,</li>
                    <li>Telefon araması ile iletişime geçilmesi,</li>
                    <li>
                      İnternet sitesinin ziyaret edilmesi ve sosyal medya
                      platformları üzerinden irtibata geçilmesi,
                    </li>
                    <li>
                      Online satış platformları, turizm acenteleri, organizasyon
                      firmaları, fuar veya seminer ziyaretlerinde kartvizit
                      paylaşımları,
                    </li>
                    <li>
                      Çözüm ortağı kurum veya kuruluşlar aracılığıyla
                      iletilmesi.
                    </li>
                  </ul>

                  <h5>f) KVKK’nın 11. Maddesi Kapsamındaki Haklarınız</h5>
                  <p>
                    KVKK’nın 11. maddesi kapsamında, veri sahibi olarak
                    haklarınız bulunmakta olup bu haklarınız ile ilgili
                    taleplerinizi aşağıda belirtilen yöntemlerle
                    iletebilirsiniz:
                  </p>
                  <ul>
                    <li>
                      <strong>Yazılı Talep:</strong> Kurtköy Yavuzselim mah.,
                      İstasyon Cad. Souğuksu sok. No:12, 54600 Sapanca/Sakarya
                      adresine bizzat gelerek veya iadeli taahhütlü mektup ile.
                    </li>
                    <li>
                      <strong>E-Posta Talep:</strong> Güvenli elektronik imza,
                      mobil imza ya da tarafınızca daha önce bildirilen ve
                      sistemlerimizde kayıtlı bulunan elektronik posta adresini
                      kullanarak{" "}
                      <a href="mailto:info@mayabungalov.com">
                        info@mayabungalov.com
                      </a>{" "}
                      adresine elektronik posta göndererek.
                    </li>
                  </ul>

                  <h5>g) Kişisel Verilerin Saklanma Süresi</h5>
                  <p>
                    MAYA Sapanca Bungalov, kişisel verilerin işleme amacının
                    ortadan kalkması ve Kanunlar ile ilgili sair mevzuat
                    kapsamında belirlenen zorunlu saklama sürelerinin dolmasıyla
                    birlikte kişisel verileri siler, yok eder, imha eder veya
                    anonim hale getirir.
                  </p>

                  <h5>h) Değişiklik ve Güncellemeler</h5>
                  <p>
                    İş bu aydınlatma metni, 6698 sayılı Kişisel Verilerin
                    Korunması Kanunu ve ilgili sair mevzuat kapsamında
                    hazırlanmıştır. İlgili yasal mevzuat ve/veya MAYA SAPANCA
                    BUNGALOV kişisel veri işleme amaç ve politikalarında meydana
                    gelecek değişiklikler doğrultusunda bahse konu aydınlatma
                    metninde gerekli değişiklikler yapılabilir.
                  </p>
                </div>
              )}
              {activePopup === "gizlilik" && (
                <div className="popup-container">
                  <h4>Gizlilik Sözleşmesi</h4>{" "}
                  <div className="section">
                    <h3>1. SÖZLEŞMENİN TARAFLARI VE TANIMLAR</h3>
                    <p>
                      1.1. Bu sözleşme; Bungalovu kiralayan (bundan böyle
                      “KİRACI” olarak anılacaktır) ile Bungalovu kiraya veren
                      (bundan böyle “MAYA SAPANCA BUNGALOV” olarak anılacaktır)
                      arasında aşağıda yazılı şartlar dahilinde akdedilmiştir.
                    </p>
                    <p>
                      1.2. Sözleşmenin amacı; başlangıç ve bitiş tarihleri
                      önceden belirli bir süre için bungalov kiralanmasıdır.
                      MAYA SAPANCA BUNGALOV internet sitesinde ilan edilen
                      bungalovlarımızdan herhangi birine internet sayfamız
                      üzerinden veya telefon yolu ile veya elektronik posta yolu
                      ile rezervasyon yaptıran kiracı ve beraberindekiler
                      aşağıdaki şartları peşinen kabul etmiş sayılırlar.
                    </p>
                    <p>
                      1.3. Kiracı sözleşme düzenlendiği sırada 18 yaşını
                      doldurmuş olmalıdır. Sözleşme akdedilirken kiracının ve
                      yanındaki kişilerin nüfus cüzdanı veya pasaportu ile işlem
                      yapılacağı için bunlardan birinin bulundurulması
                      zorunludur. Bungalovun kiralanması aşamasında kiracının
                      beyanı doğrultusunda işlem yapılmakta olup kiracı vermiş
                      olduğu bilgilerin doğru ve eksiksiz olduğunu, bu hususta
                      doğacak tüm sorumlulukları kabul eder. Aşağıdaki şartların
                      herhangi birine aykırı davranış, “MAYA SAPANCA BUNGALOV”a,
                      rezervasyonu tek taraflı olarak iptal etme, kiracının
                      bungalovu terk etmesini isteme, alınan kapora, depozito
                      kira bedelini iade etmeme ve ödenmemiş olan kira
                      bedellerinin tamamını talep etme hakkını verir.
                    </p>
                  </div>
                  <div className="section">
                    <h3>2. SÖZLEŞMENİN KONUSU VE DİLİ</h3>
                    <p>
                      2.1. İşbu sözleşmenin konusu; MAYA Sapanca Bungalov’a ait
                      Kurtköy Yavuzselim mah., İstasyon Cad. Souğuksu sok.
                      No:12, 54600 Sapanca/Sakarya açık adresinde bulunan
                      bungalovun sözleşmenin karşı tarafı olan kiracıya, işbu
                      sözleşme ile belirlenen ücret karşılığında
                      günlük/haftalık/aylık olacak şekilde kiralanmasıdır.
                    </p>
                    <p>2.2. Sözleşme, Türkçe olarak hazırlanmıştır.</p>
                  </div>
                  <div className="section">
                    <h3>4. GENEL ŞARTLAR</h3>
                    <p>
                      4.1. MAYA SAPANCA BUNGALOV’a ait bungalovlardan herhangi
                      birine rezervasyon yaptıran kiracı, işbu sözleşmenin
                      devamında belirtilen şartları kabul etmiş sayılır. Bu
                      şartlardan herhangi birine aykırı davranış halinde MAYA
                      SAPANCA BUNGALOV’a rezervasyonları tek taraflı iptal etme,
                      ödeme iadesi yapmama ve ücretin tamamını talep etme hakkı
                      verir.
                    </p>
                    <p>
                      4.2. Kiracı, kiralamış olduğu bungalovu
                      günlük/haftalık/aylık olarak kiralamıştır. Kiralanan
                      bungalovu işbu sözleşme ile özgülenen amacın dışında
                      kullanamaz, ödeme şekli ne olursa olsun MAYA SAPANCA
                      BUNGALOV’un izni olmadan 3. kişilere kısmen veya tamamen,
                      sözlü veya yazılı bir şekilde ivaz karşılığı kiralayamaz,
                      devredemez, bir başkasının istifadesine bırakamaz.
                    </p>
                    <p>
                      4.7. Kiracı, kiralama süresince kendi kusuruyla veya
                      kendisine eşlik eden kimselerin kusurlarıyla sebep olunan
                      kazalardan ve zararlardan sorumludur.
                    </p>
                    <p>
                      4.8. Kiracı, kiralanan bungalovu kendi isteğiyle veya
                      kendinden kaynaklanan bir sebeple ya da MAYA SAPANCA
                      BUNGALOV’un kontrolü dışında gelişen bir sebeple erken
                      terk ederse toplam kiralama bedelini ödemekle yükümlüdür.
                      Herhangi bir şekilde kısmen veya tamamen olacak şekilde
                      ödeme iadesi talep edilemez. Kiracı, konaklama bedelinin
                      hiç ödenmemiş veya kısmen ödenmiş olduğu durumlarda, MAYA
                      SAPANCA BUNGALOV’a haber vermeden kiralanan bungalovu terk
                      etmesi durumunda işbu sözleşmede belirlenen yargı
                      yerlerinde hakkında yargılama yapılacaktır.
                    </p>
                  </div>
                  <div className="section">
                    <h3>5. REZERVASYON VE ÖDEME</h3>
                    <p>
                      5.1. MAYA SAPANCA BUNGALOV’a ait bungalovlara internet
                      sitesi, sosyal medya veya telefon ile ön rezervasyon
                      yapılabilmektedir. MAYA SAPANCA BUNGALOV’a ait internet
                      sitesinden rezervasyona uygun tarihler belirtilmektedir.
                    </p>
                    <p>
                      5.3. Ön kiralama işlemi yapıldıktan sonra bungalov için
                      ödenecek toplam kiralama ücretinin %50’si kaparo ödemesi
                      olarak alınmaktadır. Bu ödeme www.mayabungalov.com adresli
                      internet sitesinden olarak yapılabileceği gibi MAYA
                      BUNGALOV’a ait Enpara Finansbank
                      TR640011100000000078603306 IBAN numaralı hesap numarasına
                      EFT/Havale yolu ile gönderilebilir. Kaparo bedeli online
                      veya havale/eft yoluyla ödendikten sonra 3 (üç) iş günü
                      içerisinde ödendiği takdirde bungalov kiracı adına rezerve
                      edilecektir.
                    </p>
                  </div>
                  <div className="section">
                    <h3>9. İPTAL ŞARTLARI VE İADE KOŞULLARI</h3>
                    <p>
                      9.1. Bungalov rezervasyonu için MAYA SAPANCA BUNGALOV’un
                      hesabına yatırılan, ön ödeme miktarının tamamı (EFT ile,
                      veya Havale ile, veya Kredi Kartı ile "tek çekim veya
                      taksitli", veya Peşin Olarak, veya Mail Order ile veya
                      vb.) kiracının bungalova giriş tarihine 15 gün kalıncaya
                      kadar yapılan iptallerde yanacaktır.
                    </p>
                    <p>
                      9.2. Kiracının iptal bildirisini MAYA SAPANCA BUNGALOV’a
                      yazılı olarak yapılması zorunlu olup yazılı yapılmayan
                      bildirimler kabul edilemez.
                    </p>
                    <p>9.3. Eğer Ön Ödeme (Kapora) Alınmışsa;</p>
                    <p>
                      a) Rezervasyon için ödenen %30'luk ön ödemenin (kaporanın)
                      kesinlikle iadesi yoktur.
                    </p>
                    <p>
                      b) Kiracının bungalova giriş tarihine 30 gün ve daha az
                      kalana kadar yapılan iptallerde ya da bungalova hiç
                      gelmeme durumunda kiracı, ödemenin %100'ünü ödemek
                      zorundadır.
                    </p>
                    <p>Eğer toplam ödemenin tamamı alınmışsa;</p>
                    <p>
                      a) Kiracının bungalova giriş tarihine 30 gün ve daha fazla
                      kalana kadar yapılan iptallerde %30'luk ön ödeme (kapora)
                      miktarı hesaplanır ve bu tutarın iadesi yoktur. Geri kalan
                      tutar ise kiracının hesabına geri iade edilir.
                    </p>
                    <p>
                      b) Kiracının bungalova giriş tarihine 30 gün ve daha az
                      kalana kadar yapılan iptallerde ya da bungalova hiç
                      gelmeme durumunda yapılmış olan ödemenin iadesi yoktur.
                    </p>
                    <p>
                      9.4. Mücbir sebep (deprem, sel, afet vb.) olan durumlarda
                      alınan ödemenin tamamı kiracıya iade edilecektir.
                    </p>
                    <p>
                      9.5. MAYA SAPANCA BUNGALOV tarafından kiralama işleminin
                      herhangi bir nedenle iptal edilmesi durumunda kiracıya
                      iptal işlemi bildirim en kısa sürede yapılacaktır. MAYA
                      SAPANCA BUNGALOV, portföyünde bulunan diğer bungalovlar
                      uygunluk durumuna göre kiracıya alternatif sunacaktır.
                      Alternatif bir bungalov bulunamadığı durumda, kiracıya
                      ödenmiş bedel iade edilecektir.
                    </p>
                  </div>
                  <div className="section">
                    <h3>10. GİZLİLİK VE KİŞİSEL VERİLERİN KORUNMASI</h3>
                    <p>
                      10.1. Kiracı, işbu sözleşmeye istinaden sağlanan kişisel
                      verilerin, sadece bungalov kiralama işlemi ve bu
                      işlemlerin yürütülmesi amacıyla kullanılacağını ve ilgili
                      mevzuat çerçevesinde korunacağını kabul eder.
                    </p>
                    <p>
                      10.2. Kişisel veriler, Kiracının açık rızası olmaksızın
                      üçüncü şahıslarla paylaşılmayacaktır.
                    </p>
                    <p>
                      10.3. Kiracı, kişisel verilerinin işlenmesi ve saklanması
                      konusunda MAYA SAPANCA BUNGALOV’un gizlilik politikalarını
                      kabul eder.
                    </p>
                  </div>
                  <div className="section">
                    <h3>11. YÜRÜRLÜK</h3>
                    <p>
                      11.1. Bu sözleşme, taraflarca imzalandığı tarihten
                      itibaren yürürlüğe girer.
                    </p>
                    <p>
                      11.2. İşbu sözleşmenin herhangi bir hükmünün geçersiz veya
                      uygulanamaz hale gelmesi durumunda, diğer hükümlerin
                      geçerliliği ve uygulanabilirliği etkilenmeyecektir.
                    </p>
                  </div>
                  <div className="section">
                    <h3>12. UYUŞMAZLIKLARIN ÇÖZÜMÜ</h3>
                    <p>
                      12.1. İşbu sözleşmeden doğabilecek her türlü uyuşmazlık,
                      Türkiye Cumhuriyeti Kanunlarına tabi olup, Sakarya
                      Mahkemeleri ve İcra Daireleri yetkilidir.
                    </p>
                  </div>
                  <div className="section">
                    <h3>13. İLETİŞİM BİLGİLERİ</h3>
                    <p>
                      MAYA SAPANCA BUNGALOV’un iletişim bilgileri şu şekildedir:
                    </p>
                    <p>
                      Adres: Kurtköy Yavuzselim mah., İstasyon Cad. Souğuksu
                      sok. No:12, 54600 Sapanca/Sakarya
                    </p>
                    <p>Telefon: +905051606292</p>
                  </div>
                </div>
              )}
              {activePopup === "mesafelisatis" && (
                <div className="popup-container">
                  <h4>Mesafeli Satış Sözleşmesi</h4>
                  <p>
                    <strong>1. TARAFLAR</strong>
                  </p>
                  <p>
                    <strong>1.1. Satıcı:</strong>
                    <br />
                    <strong>Ticaret Unvanı:</strong> GUMMY TURİZM GIDA OTELCİLİK
                    İNŞAAT TİCARET LİMİTED ŞİRKETİ
                    <br />
                    <strong>Adres:</strong> Kurtköy Yavuzselim mah., İstasyon
                    Cad. Soğuksu sok. No:12, 54600 Sapanca/Sakarya
                    <br />
                    <strong>Telefon:</strong> +90 505 160 62 92
                    <br />
                    <strong>E-posta:</strong> [e-posta adresi]
                  </p>
                  <p>
                    <strong>1.2. Alıcı (Tüketici):</strong>
                    <br />
                    Adı Soyadı: [Tüketici Adı]
                    <br />
                    Adres: [Tüketici Adresi]
                    <br />
                    Telefon: [Tüketici Telefonu]
                    <br />
                    E-posta: [Tüketici E-posta Adresi]
                  </p>
                  <p>
                    <strong>2. SÖZLEŞMENİN KONUSU</strong>
                  </p>
                  <p>
                    İşbu Mesafeli Satış Sözleşmesi, Alıcı’nın Satıcı’ya ait
                    [mayabungalov.com] internet sitesi üzerinden elektronik
                    ortamda siparişini yaptığı konaklama hizmetinin satışı ve
                    ifası ile ilgili olarak, 6502 sayılı Tüketicinin Korunması
                    Hakkında Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik
                    hükümleri gereğince tarafların hak ve yükümlülüklerini
                    düzenler.
                  </p>
                  <p>
                    <strong>3. HİZMET VE ÖDEME BİLGİLERİ</strong>
                  </p>
                  <p>
                    3.1. Satışa konu hizmetin temel özellikleri (türü, süresi,
                    fiyatı vb.) Satıcı’ya ait internet sitesinde yayınlanmıştır.
                    Satıcı, ilan ettiği fiyatlar üzerinde değişiklik yapma
                    hakkını saklı tutar. Ancak, Alıcı tarafından yapılan
                    siparişin ardından Alıcı lehine olan değişiklikler hariç,
                    fiyat değişikliği yapılamaz.
                    <br />
                    <br />
                    3.2. Siparişe konu hizmetin tüm vergiler dâhil satış fiyatı
                    aşağıda gösterilmiştir:
                    <br />
                    <br />
                    - Hizmet Türü: [Bungalov Konaklama]
                    <br />
                    - Konaklama Süresi: [Gün Sayısı]
                    <br />
                    - Hizmet Bedeli: [Toplam Tutar] TL
                    <br />
                    <br />
                    3.3. Ödeme yöntemi: [Kredi Kartı/Banka Kartı/Havale/EFT]
                  </p>
                  <p>
                    <strong>4. CAYMA HAKKI</strong>
                  </p>
                  <p>
                    4.1. Alıcı, hizmetin ifasına başlandığı tarihten itibaren
                    cayma hakkını kullanamaz. Alıcı, cayma hakkını
                    kullanamayacağına dair bilgilendirilmiştir.
                    <br />
                    <br />
                    4.2. Cayma hakkı kapsamındaki talepler, hizmet ifasından
                    önce, 7 (yedi) gün içerisinde yazılı olarak veya Satıcı’nın
                    internet sitesinde yer alan iletişim kanalları aracılığıyla
                    bildirilebilir. Cayma hakkı talebi işleme alındığında, iptal
                    politikası doğrultusunda iade yapılacaktır.
                  </p>
                  <p>
                    <strong>5. İPTAL VE İADE KOŞULLARI</strong>
                  </p>
                  <p>
                    5.1. Alıcı, hizmetin başlamasından 30 (otuz) gün önce iptal
                    talebinde bulunduğunda, vergiler ve iadesi mümkün olmayan
                    masraflar hariç olmak üzere ödediği bedelin tamamı iade
                    edilir.
                    <br />
                    <br />
                    5.2. Hizmetin başlamasından 15 (on beş) gün öncesine kadar
                    yapılan iptallerde, ödemenin %50’si iade edilir.
                    <br />
                    <br />
                    5.3. Hizmetin başlamasına 7 (yedi) günden az bir süre kala
                    yapılan iptallerde veya erken çıkış taleplerinde hiçbir
                    bedel iade edilmez.
                    <br />
                    <br />
                    5.4. İptal ve iade talepleri, Alıcı’nın rezervasyon yaparken
                    kullandığı ödeme yöntemi üzerinden gerçekleştirilecektir.
                  </p>
                  <p>
                    <strong>6. HİZMETİN İFASI VE TESLİMATI</strong>
                  </p>
                  <p>
                    6.1. Alıcı, Satıcı’nın internet sitesi üzerinden yapmış
                    olduğu rezervasyonun ardından, belirtilen tarihlerde Satıcı
                    tarafından hizmetin ifa edileceğini kabul eder.
                    <br />
                    <br />
                    6.2. Hizmetin ifa edileceği tarih ve yer, Alıcı tarafından
                    seçilmiş olan bungalov ve konaklama süresi üzerinden
                    belirlenecektir.
                  </p>
                  <p>
                    <strong>7. MÜCBİR SEBEPLER</strong>
                  </p>
                  <p>
                    7.1. Mücbir sebepler, olumsuz hava koşulları, sel, deprem,
                    yangın, savaş, grev, terör gibi, tarafların kontrolü dışında
                    gelişen olaylar olarak kabul edilir. Mücbir sebep
                    hallerinde, hizmetin ifası ertelenebilir veya iptal
                    edilebilir.
                    <br />
                    <br />
                    7.2. Bu gibi durumlarda, Alıcı'nın iade talep hakkı
                    bulunmaktadır. Ancak Alıcı'nın başka tazminat veya ek hizmet
                    talep hakkı bulunmamaktadır.
                  </p>
                  <p>
                    <strong>8. UYUŞMAZLIKLARIN ÇÖZÜMÜ</strong>
                  </p>
                  <p>
                    İşbu sözleşmeden doğan uyuşmazlıklarda Sakarya Tüketici
                    Hakem Heyetleri ve Mahkemeleri yetkilidir.
                  </p>
                  <p>
                    <strong>9. YÜRÜRLÜK</strong>
                  </p>
                  <p>
                    İşbu sözleşme, Alıcı tarafından elektronik ortamda
                    onaylandığı tarihte yürürlüğe girer.
                  </p>
                  <p>
                    <strong>10. GENEL HÜKÜMLER</strong>
                  </p>
                  <p>
                    10.1. Alıcı, Satıcı’nın internet sitesinde yer alan sözleşme
                    hükümlerini okuduğunu, anladığını ve kabul ettiğini beyan
                    eder.
                    <br />
                    <br />
                    10.2. Satıcı, hizmetin ifası sırasında oluşabilecek
                    değişiklikleri Alıcı’ya önceden bildirecektir.
                  </p>
                </div>
              )}
              {activePopup === "iade" && (
                <div className="popup-container">
                  <h4>İptal ve İade Koşulları</h4>
                  <h4>CAYMA HAKKI</h4>
                  <p>
                    MAYA SAPANCA BUNGALOV tarafından satışı yapılan
                    ürün/hizmetin niteliği gereği işbu sözleşme kapsamında
                    yapılan mesafeli satışlarda Mesafeli Sözleşmeler Yönetmeliği
                    madde 15 g) uyarınca TÜKETİCİ’nin cayma hakkı
                    bulunmamaktadır.
                  </p>
                  <h4>TÜKETİCİ TARAFINDAN İPTAL VE DEĞİŞİKLİK</h4>
                  <p>
                    TÜKETİCİ’nin yapılan rezervasyonlarda iptal ve değişiklikler
                    talep etmesi aşağıda belirtilen koşullara tabidir:
                  </p>
                  <h5>İPTAL</h5>
                  <p>
                    <strong>Mesafeli Satış Sözleşmesi</strong>
                  </p>
                  <p>
                    7.1. TÜKETİCİ’nin konaklama süresinin başlamasından 30
                    (otuz) gün öncesine kadar iptal bildiriminde bulunması
                    halinde, ödenmesi zorunlu vergi, harç ve benzeri yasal
                    yükümlülüklerden doğan masraflar ile üçüncü kişilere ödenip
                    iadesi mümkün olmayan bedeller hariç olmak üzere ödediği
                    bedel TÜKETİCİ’ye iade edilir.
                  </p>
                  <p>
                    7.2. TÜKETİCİ’nin konaklama süresinin başlamasından 15 (on
                    beş) gün öncesine kadar yapılan iptal taleplerinde Sözleşme
                    kapsamında ödenen bedelin %50’si ile ödenen tüm vergi, harç
                    ve benzeri masraflar ve üçüncü kişilere ödenip iadesi mümkün
                    olmayan bedeller hariç olmak üzere mahsup edilerek kalan
                    tutar TÜKETİCİ’ye iade edilir.
                  </p>
                  <p>
                    7.3. TÜKETİCİ’nin konaklama süresinin başlamasından 7 (yedi)
                    gün öncesine kadar yapılan iptal taleplerinde Sözleşme
                    kapsamında ödenen bedelin %75’i ile ödenen tüm vergi, harç
                    ve benzeri masraflar ve üçüncü kişilere ödenip iadesi mümkün
                    olmayan bedeller hariç olmak üzere mahsup edilerek kalan
                    tutar TÜKETİCİ’ye iade edilir.
                  </p>
                  <p>
                    7.4. Konaklama başlangıç tarihine 3 (üç) gün ve daha az bir
                    süre kala yapılan iptal taleplerinde TÜKETİCİ’ye hiçbir
                    bedel iade edilmeyecektir. Konaklama başlangıcından sonra
                    erken çıkış talebinde bulunması halinde hiçbir bedel iade
                    edilmeyecektir.
                  </p>
                  <p>
                    7.5. Yapılan iptallerde ortaya çıkacak iadeler rezervasyon
                    sırasında kullanılan kredi kartına yapılacaktır.
                  </p>
                  <h5>DEĞİŞİKLİK</h5>
                  <p>
                    <strong>Mesafeli Satış Sözleşmesi</strong>
                  </p>
                  <p>
                    7.6. Tüketici tarafından rezervasyonunda değişiklik yapma
                    talebi en geç konaklamanın başlayacağı günden 15 (on beş)
                    gün öncesine kadar MAYA SAPANCA BUNGALOV’a yazılı olarak
                    bildirilmek zorundadır. TÜKETİCİ’nin belirtilen tarihten
                    sonra bildireceği değişiklik taleplerini MAYA SAPANCA
                    BUNGALOV reddetme hakkını saklı tutar.
                  </p>
                  <p>
                    7.7. MAYA SAPANCA BUNGALOV değişiklik talebine, talep edilen
                    tarihlerde boş oda bulunması koşulu ile uyum sağlayacaktır.
                    Aksi takdirde TÜKETİCİ, MAYA SAPANCA BUNGALOV tarafından
                    önerilen tarihler arasından seçim yapacak veya 7.1 ila 7.4
                    maddelerinde düzenlenen koşullar dahilinde rezervasyonu
                    iptal edebilecektir.
                  </p>
                  <p>
                    7.8. TÜKETİCİ, talebi doğrultusunda gerçekleştirilen
                    değişikliklerden kaynaklanan fiyat farklarını eksiksiz
                    ödemeyi peşinen kabul, beyan ve taahhüt eder. Değişiklik
                    işlemi gerçekleştirildikten ve fiyat farkı tutarı kendisine
                    bildirildikten sonra en geç 24 (yirmidört) saat içerisinde
                    fark tutarı ödenmediği takdirde, değişiklik talebi geçersiz
                    sayılacaktır. Tüketici, indirimli satış döneminde satın
                    almış olduğu indirimli erken rezervasyon ürününde yeni
                    hizmetin fiyatı, iptal edilen önceki hizmet fiyatından düşük
                    olması halinde aradaki farkı TÜKETİCİ’ye iade edecektir.
                    Herhangi bir sebeple tarih değişikliği yaptırmak isterse,
                    talepte bulunduğu tarihte geçerli olan liste fiyatları
                    üzerinden indirimsiz rezervasyon değişikliği yapılacağını
                    kabul eder.
                  </p>
                  <h4>MAYA SAPANCA BUNGALOV TARAFINDAN İPTAL VE DEĞİŞİKLİK</h4>
                  <h5>İPTAL</h5>
                  <p>
                    8.1. MAYA SAPANCA BUNGALOV’un, gerekli tüm özeni
                    göstermesine rağmen, hizmetin başlamasından önce veya
                    devamında, hizmetin sağlanmasına engel teşkil eden mücbir
                    sebepler veya üçüncü kişilerden kaynaklanan durumlar meydana
                    gelmesi halinde MAYA BUNGALOV sözleşmeyi iptal edebilir.
                    MAYA SAPANCA BUNGALOV bu durumu tespit etmesi ile birlikte
                    TÜKETİCİ’ye en kısa sürede bildirim yapacaktır. MAYA SAPANCA
                    BUNGALOV bu durumda TÜKETİCİ’ye kullanmadığı hizmetlerin
                    bedelini iade eder. TÜKETİCİ’nin başka herhangi bir tazminat
                    hakkı yoktur.
                  </p>
                  <p>
                    8.2. TÜKETİCİ’nin hizmet başlangıç tarihini kaçırdığı
                    takdirde MAYA SAPANCA BUNGALOV’a konaklamanın kalan
                    günlerini kullanacağını yazılı olarak bildirmemesi halinde
                    MAYA BUNGALOV rezervasyonu 24 (yirmidört) saatin
                    tamamlanmasıyla iptal etme ve ayrılan odaları başka kişilere
                    kullandırma hakkına sahiptir. TÜKETİCİ bu gibi iptal
                    sebebiyle MAYA BUNGALOV’dan herhangi bir ücret iadesi talep
                    edemez.
                  </p>
                  <h5>MÜCBİR SEBEPLER</h5>
                  <p>
                    Olumsuz hava koşulları, yol engeli, terör, grev-lokavt,
                    savaş ihtimali, sel, yangın, öngörülemez teknik hususlar vb.
                    haller mücbir sebep sayılır. Bu gibi nedenlerin meydana
                    gelmesi durumunda MAYA SAPANCA BUNGALOV hizmet vermeyebilir
                    veya devam edemeyebilir. Bu gibi durumlarda TÜKETİCİ’nin
                    tazminat hakkı yoktur.
                  </p>
                  <p>
                    8.4. MAYA SAPANCA BUNGALOV’un yasal yükümlülüğü ve mücbir
                    sebepler dışında sözleşmeyi iptal etmesi halinde,
                    TÜKETİCİ’ye kullanmadığı hizmetlerin bedeli iade edilir.
                  </p>
                  <h4>EK MADDELER</h4>
                  <p>
                    Konaklama başlangıcından sonra erken çıkış talebinde
                    bulunması halinde hiçbir bedel iade edilmeyecektir. TÜKETİCİ
                    bu şartı peşinen kabul etmiş sayılır.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
