import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Gallery.css";
import resim1 from "../assets/images/gallery/1.jpg";
import resim2 from "../assets/images/gallery/2.jpg";
import resim3 from "../assets/images/gallery/3.jpg";
import resim4 from "../assets/images/gallery/4.jpg";
import resim5 from "../assets/images/gallery/5.jpg";
import resim6 from "../assets/images/gallery/6.jpg";
import resim7 from "../assets/images/gallery/7.jpg";
import resim8 from "../assets/images/gallery/8.jpg";
import resim9 from "../assets/images/gallery/9.jpg";
import resim10 from "../assets/images/gallery/10.jpg";
import resim11 from "../assets/images/gallery/11.jpg";
import resim12 from "../assets/images/gallery/12.jpg";
import resim13 from "../assets/images/gallery/13.jpg";
import resim14 from "../assets/images/gallery/14.jpg";
import resim15 from "../assets/images/gallery/15.jpg";
import resim16 from "../assets/images/gallery/16.jpg";
import resim17 from "../assets/images/gallery/17.jpg";
import resim18 from "../assets/images/gallery/18.jpg";
import resim19 from "../assets/images/gallery/19.jpg";
import resim20 from "../assets/images/gallery/20.jpg";
import resim21 from "../assets/images/gallery/21.jpg";
import resim22 from "../assets/images/gallery/22.jpg";
import resim23 from "../assets/images/gallery/23.jpg";
import resim24 from "../assets/images/gallery/24.jpg";
import resim25 from "../assets/images/gallery/25.jpg";
import resim26 from "../assets/images/gallery/26.jpg";
import resim27 from "../assets/images/gallery/27.jpg";
import resim28 from "../assets/images/gallery/28.jpg";
import resim29 from "../assets/images/gallery/29.jpg";
import resim30 from "../assets/images/gallery/30.jpg";
import resim31 from "../assets/images/gallery/31.jpg";
import resim32 from "../assets/images/gallery/32.jpg";
import resim33 from "../assets/images/gallery/33.jpg";
import resim34 from "../assets/images/gallery/34.jpg";
import resim35 from "../assets/images/gallery/35.jpg";
import resim36 from "../assets/images/gallery/36.jpg";
import resim37 from "../assets/images/gallery/37.jpg";
import resim38 from "../assets/images/gallery/38.jpg";
import resim39 from "../assets/images/gallery/39.jpg";
import resim40 from "../assets/images/gallery/40.jpg";
import resim41 from "../assets/images/gallery/41.jpg";
import resim42 from "../assets/images/gallery/42.jpg";
import resim43 from "../assets/images/gallery/43.jpg";
import resim44 from "../assets/images/gallery/44.jpg";
import resim45 from "../assets/images/gallery/45.jpg";
import resim46 from "../assets/images/gallery/46.jpg";
import resim47 from "../assets/images/gallery/47.jpg";
import resim48 from "../assets/images/gallery/48.jpg";
import resim49 from "../assets/images/gallery/49.jpg";
import resim50 from "../assets/images/gallery/50.jpg";
import resim51 from "../assets/images/gallery/51.jpg";
import resim52 from "../assets/images/gallery/52.jpg";
import resim53 from "../assets/images/gallery/53.jpg";
import resim54 from "../assets/images/gallery/54.jpg";
import resim55 from "../assets/images/gallery/55.jpg";
import resim56 from "../assets/images/gallery/56.jpg";
import resim57 from "../assets/images/gallery/57.jpg";
import resim58 from "../assets/images/gallery/58.jpg";
import resim59 from "../assets/images/gallery/59.jpg";
import resim60 from "../assets/images/gallery/60.jpg";

const images = [
  {
    original: resim1,
    thumbnail: resim1,
  },
  {
    original: resim2,
    thumbnail: resim2,
  },
  {
    original: resim3,
    thumbnail: resim3,
  },
  {
    original: resim4,
    thumbnail: resim4,
  },
  {
    original: resim5,
    thumbnail: resim5,
  },
  {
    original: resim6,
    thumbnail: resim6,
  },
  {
    original: resim7,
    thumbnail: resim7,
  },
  {
    original: resim8,
    thumbnail: resim8,
  },
  {
    original: resim9,
    thumbnail: resim9,
  },
  {
    original: resim10,
    thumbnail: resim10,
  },
  {
    original: resim11,
    thumbnail: resim11,
  },
  {
    original: resim12,
    thumbnail: resim12,
  },
  {
    original: resim13,
    thumbnail: resim13,
  },
  {
    original: resim14,
    thumbnail: resim14,
  },
  {
    original: resim15,
    thumbnail: resim15,
  },
  {
    original: resim16,
    thumbnail: resim16,
  },
  {
    original: resim17,
    thumbnail: resim17,
  },
  {
    original: resim18,
    thumbnail: resim18,
  },
  {
    original: resim19,
    thumbnail: resim19,
  },
  {
    original: resim20,
    thumbnail: resim20,
  },
  {
    original: resim21,
    thumbnail: resim21,
  },
  {
    original: resim22,
    thumbnail: resim22,
  },
  {
    original: resim23,
    thumbnail: resim23,
  },
  {
    original: resim24,
    thumbnail: resim24,
  },
  {
    original: resim25,
    thumbnail: resim25,
  },
  {
    original: resim26,
    thumbnail: resim26,
  },
  {
    original: resim27,
    thumbnail: resim27,
  },
  {
    original: resim28,
    thumbnail: resim28,
  },
  {
    original: resim29,
    thumbnail: resim29,
  },
  {
    original: resim30,
    thumbnail: resim30,
  },
  {
    original: resim31,
    thumbnail: resim31,
  },
  {
    original: resim32,
    thumbnail: resim32,
  },
  {
    original: resim33,
    thumbnail: resim33,
  },
  {
    original: resim34,
    thumbnail: resim34,
  },
  {
    original: resim35,
    thumbnail: resim35,
  },
  {
    original: resim36,
    thumbnail: resim36,
  },
  {
    original: resim37,
    thumbnail: resim37,
  },
  {
    original: resim38,
    thumbnail: resim38,
  },
  {
    original: resim39,
    thumbnail: resim39,
  },
  {
    original: resim40,
    thumbnail: resim40,
  },
  {
    original: resim41,
    thumbnail: resim41,
  },
  {
    original: resim42,
    thumbnail: resim42,
  },
  {
    original: resim43,
    thumbnail: resim43,
  },
  {
    original: resim44,
    thumbnail: resim44,
  },
  {
    original: resim45,
    thumbnail: resim45,
  },
  {
    original: resim46,
    thumbnail: resim46,
  },
  {
    original: resim47,
    thumbnail: resim47,
  },
  {
    original: resim48,
    thumbnail: resim48,
  },
  {
    original: resim49,
    thumbnail: resim49,
  },
  {
    original: resim50,
    thumbnail: resim50,
  },
  {
    original: resim51,
    thumbnail: resim51,
  },
  {
    original: resim52,
    thumbnail: resim52,
  },
  {
    original: resim53,
    thumbnail: resim53,
  },
  {
    original: resim54,
    thumbnail: resim54,
  },
  {
    original: resim55,
    thumbnail: resim55,
  },
  {
    original: resim56,
    thumbnail: resim56,
  },
  {
    original: resim57,
    thumbnail: resim57,
  },
  {
    original: resim58,
    thumbnail: resim58,
  },
  {
    original: resim59,
    thumbnail: resim59,
  },
  {
    original: resim60,
    thumbnail: resim60,
  },
];

const Gallery = () => {
  return (
    <section id="galeri" className="image-gallery-section">
      <h1 className="section-header">Galeri</h1>
      <div className="gallery-container">
        <ImageGallery
          useBrowserFullscreen={false}
          items={images}
          showThumbnails={true}
          showFullscreenButton={true}
          showPlayButton={false}
        />
      </div>
    </section>
  );
};

export default Gallery;
